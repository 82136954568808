<template functional>
    <div class="summary-divider" :class="[data.class, data.staticClass]">
        <div class="summary-divider__line"></div>
        <div class="summary-divider__capsule">{{ props.text }}</div>
    </div>
</template>

<script>
export default {
    name: 'AudienceSummaryDivider',
    props: {
        text: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped lang="scss">
.summary-divider {
    position: relative;
    display: flex;
    justify-content: center;

    &__line {
        border-top: 1px solid $input-border-color;
        width: 100%;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-1px);
    }

    &__capsule {
        padding: 2px 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 9px;
        border-radius: 10px;
        width: 60px;
        background-color: $input-border-color;
        position: relative;
        z-index: 2;
    }
}
</style>
