<template>
    <div class="audience-summary-item">
        <AudienceSummaryRow
            v-if="isRule"
            :number="index + 1"
            :type="summaryObject.type"
            :title="summaryObject.title"
            :member-count="summaryObject.estimate"
            :exclude="summaryObject.exclude"
            @deleteRule="$emit('deleteRule', summaryObject.id)"
        />

        <div
            v-else
            class="audience-summary-group"
            :class="`audience-summary-group--${summaryObject.operator}`"
        >
            <div v-for="(childObject, idx) in summaryObject.children" :key="idx">
                <AudienceSummaryItem
                    :summary-object="childObject"
                    :depth="depth + 1"
                    :index="idx"
                    @deleteRule="$emit('deleteRule', $event)"
                />

                <AudienceSummaryDivider
                    v-if="summaryObject.operator && idx < summaryObject.children.length - 1"
                    :text="summaryObject.operator"
                    class="audience-summary__divider"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { ruleTypeAttributes } from '@/components/Audience/ruleTypeAttributes';
import AudienceSummaryRow from '@/components/Audience/AudienceSummaryRow';
import AudienceSummaryDivider from '@/components/Audience/AudienceSummaryDivider';
import { mapGetters } from 'vuex';

export default {
    name: 'AudienceSummaryItem',
    components: {
        AudienceSummaryDivider,
        AudienceSummaryRow,
    },
    props: {
        summaryObject: {
            type: Object,
            required: true,
            validator: (value) => {
                return value === null || typeof value === 'object';
            },
        },
        depth: {
            type: Number,
            default: 0,
        },
        index: {
            type: Number,
            default: 0,
        },
    },
    computed: {
        isRule() {
            return this.summaryObject.ruleType === 'rule';
        },
    },
};
</script>

<style scoped lang="scss">
/deep/ {
    .audience-summary__divider {
        margin-top: -9px;
        margin-bottom: -7px;
    }
}

.audience-summary-item {
    background-color: $default-light-color;
}

.audience-summary-group {
    &__children {
        padding-left: 20px;
    }
}
</style>
