<template>
    <div :class="classObject">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'ContentPlaceholders',
    props: {
        rounded: {
            type: Boolean,
            default: false,
        },
        animated: {
            type: Boolean,
            default: true,
        },
    },
    computed: {
        classObject() {
            return {
                'content-placeholders': true,
                'content-placeholders--rounded': this.rounded,
                'content-placeholders--animated': this.animated,
            };
        },
    },
};
</script>

<style lang="scss">
// Variables
$content-placeholders-primary-color: #ccc !default;
$content-placeholders-secondary-color: rgba(#dfe1e9, 0.5) !default;
$content-placeholders-border-radius: 6px !default;
$content-placeholders-line-height: 15px !default;
$content-placeholders-spacing: 10px !default;

// Animations
@keyframes contentPlaceholdersAnimation {
    0% {
        transform: translate3d(-30%, 0, 0);
    }

    100% {
        transform: translate3d(100%, 0, 0);
    }
}

// Mixins
@mixin content-placeholders {
    position: relative;
    overflow: hidden;
    height: $content-placeholders-line-height;
    background: $content-placeholders-secondary-color;

    .content-placeholders--rounded & {
        border-radius: $content-placeholders-border-radius;
    }

    .content-placeholders--animated &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        max-width: 1000px;
        height: 100%;
        background: linear-gradient(
            to right,
            transparent 0%,
            darken($content-placeholders-secondary-color, 5%) 15%,
            transparent 30%
        );
        animation-duration: 1.5s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: contentPlaceholdersAnimation;
        animation-timing-function: linear;
    }
}

@mixin content-placeholders-spacing {
    [class^='content-placeholders-'] + & {
        margin-top: 2 * $content-placeholders-spacing;
    }
}

.content-placeholders-text {
    @include content-placeholders-spacing;

    &__line {
        @include content-placeholders;
        width: 100%;
        margin-bottom: $content-placeholders-spacing;
    }
}
</style>
