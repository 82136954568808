<template>
    <AudienceSummaryPlaceholder v-if="isLoading" />
    <div v-else class="audience-summary">
        <div class="audience-summary__title m-b-2">I want to target audience members...</div>

        <AudienceSummaryItem
            :summary-object="summaryObject"
            @deleteRule="$emit('deleteRule', $event)"
        />

        <div class="audience-summary__total">
            Total Unique Members: {{ summaryObject.estimate }}
        </div>
    </div>
</template>

<script>
import AudienceSummaryItem from '@/components/Audience/AudienceSummaryItem';
import AudienceSummaryPlaceholder from '@/components/Audience/AudienceSummaryPlaceholder';

export default {
    name: 'AudienceSummary',
    components: {
        AudienceSummaryPlaceholder,
        AudienceSummaryItem,
    },
    props: {
        summaryObject: {
            type: Object,
            required: true,
            validator: (value) => {
                return value === null || typeof value === 'object';
            },
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped lang="scss">
.audience-summary {
    &__title {
        font-size: 20px;
    }

    &__total {
        padding-top: 20px;
        border-top: 1px solid $input-border-color;
        text-align: right;
        font-size: $font-size-sm;
        color: $default-text-color;
    }
}
</style>
