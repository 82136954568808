<template>
    <div class="audience-rule">
        <div class="m-b-2">{{ title }}</div>

        <div v-if="errors && errors.length" class="form-field-error-message audience-rule__errors">
            <Icon name="alert" />{{ errors[0] }}
        </div>

        <IconButton class="remove-button" icon="cross-simple" @click="$emit('deleteRule', id)" />

        <AutoComplete
            id="attribute"
            v-model="autoCompleteModel"
            class="m-b-2"
            name="attribute"
            label="Attribute"
            :icon-color="iconColor"
            :items="autoCompleteItems"
            placeholder="Search for an attribute"
            @query="attributeInput = $event"
        />

        <template v-if="showAudienceRuleOptions">
            <component
                :is="currentAttribute"
                :rule="rule"
                class="m-b-2"
                @openLogicJump="$emit('openLogicJump', $event)"
                @input="(val) => handleChange('value', val)"
                @updateResponses="(val) => handleChange('responseItems', val)"
            />

            <div class="audience-rule-additional-options">
                <Checkbox
                    :id="`excludeMatches_${id}`"
                    :value="rule.excludeMatches"
                    class="exclude-matches"
                    label="Exclude Matches"
                    :has-margin-bottom="false"
                    :disabled="disableExcludeMatches"
                    @input="(val) => handleChange('excludeMatches', val)"
                />

                <button class="clear-fields-button" @click="handleClearFields">Clear Fields</button>
            </div>
        </template>
    </div>
</template>

<script>
import { RuleAttributeType } from '@/store/enums/ruleAttributeType.enum';
import { ruleTypeAttributes } from '@/components/Audience/ruleTypeAttributes';
import AutoComplete from '@/components/forms/AutoComplete';
import colors from '@styles/abstracts/_variables.colors.scss';
import IconButton from '@/components/ui/IconButton';
import Checkbox from '@/components/forms/Checkbox';
import Input from '@/components/forms/Input';
import Icon from '@/components/ui/Icon';

const defaultState = () => ({
    attributeInput: '',
    selectedAttribute: {},
});

const attributeComponentMap = {
    [RuleAttributeType.CompletedConvo]: () => import('./attributes/AttributeConvo'),
    [RuleAttributeType.StartedConvo]: () => import('./attributes/AttributeConvo'),
    [RuleAttributeType.RespondedToQuestion]: () => import('./attributes/AttributeMessage'),
    [RuleAttributeType.UserLocation]: () => import('./attributes/AttributeLocation'),
    [RuleAttributeType.JoinedChannel]: () => import('./attributes/AttributeChannel'),
};

export default {
    name: 'AudienceRule',
    components: {
        AutoComplete,
        IconButton,
        Checkbox,
        Input,
        Icon,
    },
    props: {
        id: {
            type: Number,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        rule: {
            type: Object,
            required: true,
        },
        errors: {
            type: Array,
            default: () => [],
        },
        disableExcludeMatches: {
            type: Boolean,
            default: false,
        },
    },
    data: defaultState,
    computed: {
        iconColor: () => colors['first-primary-color'],
        currentAttribute() {
            if (!attributeComponentMap[this.selectedAttribute.name]) {
                return () => null;
            }
            return attributeComponentMap[this.selectedAttribute.name];
        },
        showAudienceRuleOptions() {
            const obj = this.selectedAttribute;
            const objIsEmpty = Object.keys(obj).length === 0 && obj.constructor === Object;
            return !objIsEmpty;
        },
        ruleTypeAttributes() {
            const query = this.attributeInput.toUpperCase();
            const attributes = ruleTypeAttributes.map((attribute) => ({
                type: attribute.name,
                name: attribute.name,
                text: attribute.text,
            }));
            if (!query.length) {
                return attributes;
            }
            return attributes.filter((attr) => attr.text.toUpperCase().includes(query));
        },
        autoCompleteItems() {
            return this.ruleTypeAttributes.map((attr) => attr.text);
        },
        autoCompleteModel: {
            get() {
                return this.attributeInput;
            },
            set(value) {
                const attr = this.findAttribute('text', value);
                if (attr) {
                    this.setAttribute(attr);
                    this.$emit('updateAttribute', {
                        id: this.rule.id,
                        attribute: attr.name,
                    });
                }
            },
        },
    },
    created() {
        const attr = this.findAttribute('name', this.rule.attribute);
        if (attr) {
            this.setAttribute(attr);
        }
    },
    methods: {
        handleChange(key, value) {
            this.$emit('changed', { id: this.rule.id, key, value });
        },
        handleClearFields() {
            this.$emit('clearRule', this.rule.id);
            const { attributeInput, selectedAttribute } = defaultState();
            this.attributeInput = attributeInput;
            this.selectedAttribute = selectedAttribute;
        },
        setAttribute(attr) {
            this.selectedAttribute = attr;
            this.attributeInput = attr.text;
        },
        findAttribute(key, value) {
            return ruleTypeAttributes.find((attr) => attr[key] === value);
        },
    },
};
</script>

<style scoped lang="scss">
.audience-rule {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid $input-border-color;

    /deep/ .ti-new-tag-input {
        font-weight: $font-weight-default;
        color: $default-text-color;
    }

    &__errors {
        margin-top: -12px;
        margin-bottom: 20px;
    }
}

.attribute-autocomplete {
    margin-bottom: 25px;
}

.audience-rule-additional-options {
    display: flex;
    justify-content: space-between;
}

.clear-fields-button {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
    font-size: $font-size-xxs;
    letter-spacing: 1.6px;
    cursor: pointer;
}

.remove-button {
    width: 11px;
    height: 11px;
    position: absolute;
    right: 0;
    top: 0;

    svg {
        font-size: 11px;
    }
}
</style>
