<template>
    <div class="content-placeholders-text">
        <div v-for="n in lines" :key="n" class="content-placeholders-text__line" />
    </div>
</template>

<script>
export default {
    name: 'ContentPlaceholdersText',
    props: {
        lines: {
            type: Number,
            default: 4,
        },
    },
};
</script>
