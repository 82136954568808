<template>
    <div class="audience-summary-row">
        <div class="audience-summary-row__info">
            <p class="audience-summary-row__number">Rule {{ number }}</p>
            <p class="audience-summary-row__type">{{ type }}</p>
            <p class="audience-summary-row__title">{{ title }}</p>
        </div>

        <p v-if="exclude" class="audience-summary-row__exclude">Exclude</p>

        <p class="audience-summary-row__members">
            <span>{{ memberCount }}</span>
            <span>members</span>
        </p>

        <!-- <button
            v-tooltip.right="`Delete Rule`"
            class="audience-summary-row__delete"
            @click="$emit('deleteRule')"
        >
            <Icon name="trash-new" />
        </button>-->
    </div>
</template>

<script>
// import Icon from '@/components/ui/Icon';

export default {
    name: 'AudienceSummaryRow',
    components: {
        // Icon,
    },
    props: {
        number: {
            type: Number,
            required: true,
        },
        type: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        exclude: {
            type: Boolean,
            required: true,
        },
        memberCount: {
            type: Number,
            default: 0,
        },
    },
};
</script>

<style scoped lang="scss">
.audience-summary-row {
    padding: 7px 10px 18px;
    color: $alternate-text-color;
    font-size: $font-size-sm;
    line-height: 1.3;
    background-color: $default-light-color;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
        background-color: $bg-color;
    }

    &__info {
        margin-right: auto;
    }

    &__number {
        font-size: $font-size-xxs;
        line-height: 1.8;
    }

    &__type {
        color: $default-text-color;
        font-weight: bold;
    }

    &__members {
        min-width: 50px;
        text-align: center;
        flex-shrink: 0;

        span {
            display: block;

            &:first-child {
                font-size: 12px;
            }

            &:last-child {
                font-size: 8px;
                text-transform: uppercase;
            }
        }
    }

    &__delete {
        border-radius: 100%;
        width: 25px;
        height: 25px;
        cursor: pointer;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        justify-content: center;
        transition: color 80ms ease-in-out, background-color 80ms ease-in-out;

        &:hover,
        &:focus {
            background-color: #daf3f5;
            color: #24b2c3;
        }
    }

    &__members,
    &__delete,
    &__exclude {
        margin-left: 15px;
        margin-top: 6px;
    }

    &__exclude {
        padding: 2px 0;
        text-align: center;
        text-transform: uppercase;
        font-size: 9px;
        border-radius: 10px;
        width: 60px;
        color: #ffffff;
        background-color: #72bcb9;
        flex-shrink: 0;
    }
}
</style>
