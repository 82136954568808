<template>
    <div>
        <FullPageLoadingSpinner
            :show="
                $wait.is(
                    LoadingFlag.AudienceGetOne ||
                        LoadingFlag.ConvoGetOne ||
                        LoadingFlag.MessageResponseGet,
                )
            "
        />

        <PageHeading
            :breadcrumbs="breadcrumbs"
            :title="`${isUpdating ? 'Edit' : 'Add'} audience`"
        />

        <ErrorMessage v-if="!isValidAudience" size="large">Incomplete fields</ErrorMessage>

        <div class="page-layout-container m-t-3 p-b-4">
            <div class="page-layout-half">
                <Panel title="Rules Builder" class="panel">
                    <div class="operator-toggle m-b-2">
                        <ToggleText
                            id="operator-toggle"
                            name="operator_toggle"
                            :value="root.operator === 'and'"
                            text-on="All"
                            text-off="Any"
                            @input="handleOperatorToggle"
                        />
                    </div>

                    <AudienceRule
                        v-for="(rule, index) in ruleTree.children"
                        :id="rule.id"
                        :key="`rule-${rule.id}`"
                        :title="`Rule ${Number(index) + 1}`"
                        :rule="ruleModel(rule.id)"
                        :errors="errors.rules && errors.rules[index] ? errors.rules[index] : []"
                        :disable-exclude-matches="
                            ruleModel(rule.id).excludeMatches === false && excludeMatchesCount >= 1
                        "
                        @updateAttribute="updateRuleAttribute"
                        @openLogicJump="onOpenContentSelector"
                        @clearRule="clearAudienceRule"
                        @deleteRule="deleteAudienceRule"
                        @changed="updateAudienceRule"
                    />

                    <TextButton
                        class="m-b-3"
                        icon="plus-2"
                        @click="() => addAudienceRule({ parentId: ruleTree.id })"
                    >
                        Add more rules
                    </TextButton>

                    <div class="text-center">
                        <Toggle
                            v-if="showAthenaToggle"
                            id="useAthena"
                            :value="useAthena"
                            name="useAthena"
                            class="m-b-2"
                            label="Use Athena"
                            @input="onChange"
                        />
                        <Button has-border @click="updateAudienceSummary">
                            Update Target Audience
                        </Button>
                    </div>
                </Panel>
            </div>

            <div class="page-layout-half">
                <Panel title="Target Audience" class="m-b-4 panel">
                    <AudienceSummary
                        :is-loading="summaryLoading"
                        :summary-object="summaryObject"
                        @update="updateSummary"
                    />
                </Panel>

                <Panel title="Settings" class="panel">
                    <Input
                        id="name"
                        type="text"
                        name="name"
                        label="Audience Name"
                        placeholder="Enter the name of the audience"
                        :value="audience.name"
                        :errors="errors.name"
                        @input="(value) => updateAudience({ key: 'name', value })"
                    />

                    <Select
                        label="Export To"
                        placeholder="Select target project"
                        :has-margin-bottom="true"
                        :options="exportOptionsList"
                        :value="audience.exportTo"
                        :errors="errors.exportTo"
                        @input="handleExportOptionSelection"
                    />

                    <Select
                        label="Tenant"
                        placeholder="Select a tenant"
                        :has-margin-bottom="false"
                        :options="tenantOptions"
                        :value="audience.tenantCode"
                        :errors="errors.tenantCode"
                        @input="(value) => updateAudience({ key: 'tenantCode', value })"
                    />
                </Panel>
            </div>
        </div>

        <Stepper
            show-back-button
            show-next-button
            :show-preview-button="true"
            :next-button-is-disabled="disableSaveButton"
            :next-button-text="nextButtonText"
            preview-button-text="Export"
            :disable-preview-button="disablePreviewButton"
            :is-loading="false"
            @backButtonOnClick="backButtonClick($event)"
            @nextButtonOnClick="confirmButtonClick($event)"
            @previewButtonOnClick="exportAudienceClick($event)"
        />

        <ContentSelector
            v-if="showContentSelector"
            title="Search Content"
            :value="contentItems"
            :validation-content-type="contentType"
            @input="onChangeContentSelector"
            @close="onCloseContentSelector"
        />

        <Dialog
            show-confirm-button
            :is-dialog-visible="showConfirmDialog"
            :is-loading="$wait.is(LoadingFlag.AudienceSave)"
            @onClose="handleConfirmDialogDismiss"
            @onClickOutside="handleConfirmDialogDismiss"
            @closeOnEscapeEvent="handleConfirmDialogDismiss"
            @confirmButtonOnClick="handleConfirmDialogConfirm"
        >
            <template #header>Some changes have been made to the audience.</template>
            <template #body>Do you want to save these changes?</template>
        </Dialog>
    </div>
</template>

<script>
import { ContentType, ButtonStyle, LoadingFlag, AudienceExportStatus } from '@/store/enums';
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';
import ContentSelector from '@/components/ContentSelector/ContentSelector';
import { AudienceRule, AudienceSummary } from '@/components/Audience';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import { ToggleText, Input, Select } from '@/components/forms';
import { mapActions, mapGetters, mapState } from 'vuex';
import ErrorMessage from '@/components/ui/ErrorMessage';
import PageHeading from '@/components/ui/PageHeading';
import TextButton from '@/components/ui/TextButton';
import Stepper from '@/components/ui/Stepper';
import Dialog from '@/components/ui/Dialog';
import Button from '@/components/ui/Button';
import Panel from '@/components/ui/Panel';
import Toggle from '@/components/forms/Toggle';
import config from '@/config';

export default {
    name: 'AudienceDetails',
    components: {
        FullPageLoadingSpinner,
        AudienceSummary,
        ContentSelector,
        ErrorMessage,
        AudienceRule,
        PageHeading,
        TextButton,
        Toggle,
        ToggleText,
        Stepper,
        Dialog,
        Select,
        Button,
        Input,
        Panel,
    },
    data() {
        return {
            showConfirmDialog: false,
            LoadingFlag,
            breadcrumbs: [],
            ButtonStyle,
            showContentSelector: false,
            contentType: ContentType.Any,
            useAthena: false,
        };
    },
    computed: {
        root() {
            return this.ruleModel(this.ruleTree.id);
        },
        ...mapState('audienceDetails', [
            'errors',
            'audience',
            'activeRuleId',
            'summaryObject',
            'summaryLoading',
            'summaryMemberCount',
            'exportOptions',
        ]),
        ...mapGetters('audienceDetails', [
            'ruleTree',
            'ruleEstimate',
            'ruleModel',
            'isValidAudience',
            'isChangedAudience',
            'excludeMatchesCount',
        ]),
        ...mapGetters('logicJump', {
            contentItems: 'items',
        }),
        ...mapState('tenant', ['tenantList']),
        isUpdating() {
            return this.$route.params.audienceId !== undefined;
        },
        tenantOptions() {
            return this.tenantList.map((tenant) => ({
                id: tenant.code,
                value: tenant.name,
            }));
        },
        nextButtonText() {
            return this.isChangedAudience ? 'Save & Export Audience' : 'Finish';
        },
        totalMemberCount() {
            const estimate = this.ruleEstimate(this.ruleTree.id);
            return estimate ? estimate.count : 0;
        },
        exportOptionsList() {
            return this.exportOptions.map((option) => ({
                ...option,
                value: `${option.platform} - ${option.value}`,
            }));
        },
        disableSaveButton() {
            return this.audience.exported === AudienceExportStatus.in_progress;
        },
        disablePreviewButton() {
            if (this.isUpdating) {
                return this.audience.exported === AudienceExportStatus.in_progress;
            }
            return true;
        },
        showAthenaToggle() {
            return config.featureToggle.audienceAthenaQuery;
        },
    },
    async mounted() {
        this.setDefaultState();
        if (this.isUpdating) {
            await this.setAudience(this.$route.params.audienceId);
        }
        this.setBreadcrumbs();
        this.updateSummary();
        this.getTenants();
        this.getExportOptions();
    },
    methods: {
        ...mapActions('audienceDetails', [
            'setDefaultState',
            'saveAudience',
            'updateAudience',
            'setAudience',
            'validateAudience',
            'setActiveRuleId',
            'clearActiveRuleId',
            'addAudienceRule',
            'clearAudienceRule',
            'deleteAudienceRule',
            'updateAudienceRule',
            'updateAudienceName',
            'updateSummary',
            'hydrateRule',
            'getMessageResponses',
            'pollAudienceExportStatus',
            'updateRuleAttribute',
            'getExportOptions',
        ]),
        ...mapActions('audienceList', ['getAudiences', 'exportAudience']),
        ...mapActions('logicJump', {
            clearLogicJump: 'clear',
            getLogicJumpData: 'getData',
        }),
        ...mapActions('tenant', ['getTenants']),
        setBreadcrumbs() {
            const breadcrumbs = [];
            const audienceLink = { name: 'list-audiences' };
            breadcrumbs.push(new Breadcrumb('Audiences', audienceLink));
            breadcrumbs.push(new Breadcrumb(this.isUpdating ? this.audience.name : 'New audience'));
            this.breadcrumbs = breadcrumbs;
        },
        async confirmButtonClick() {
            await this.validateAudience();
            if (!this.isValidAudience) {
                return;
            }
            this.isUpdating ? this.handleEditConfirm() : this.handleCreateConfirm();
        },
        async exportAudienceClick() {
            await this.exportAudience(this.audience.id);
            this.$router.push({ name: 'list-audiences' });
        },
        backButtonClick() {
            this.$router.push({ name: 'list-audiences' });
        },
        async handleCreateConfirm() {
            this.showConfirmDialog = true;
        },
        handleEditConfirm() {
            if (this.isChangedAudience) {
                this.showConfirmDialog = true;
            } else {
                this.$router.push({ name: 'list-audiences' });
            }
        },
        async handleConfirmDialogConfirm() {
            const audience = await this.saveAudience();
            this.pollAudienceExportStatus(audience.id);
            this.handleConfirmDialogDismiss();
            this.$router.push({ name: 'list-audiences' });
        },
        handleConfirmDialogDismiss() {
            this.showConfirmDialog = false;
        },
        async onOpenContentSelector({ value, type, id }) {
            this.setActiveRuleId(id);
            this.clearLogicJump();
            if (value) {
                await this.getLogicJumpData({
                    targetChannel: type === ContentType.Channel ? value : '',
                    targetConvo: type === ContentType.Convo ? value : 0,
                    targetContentItem: type === ContentType.Message ? value : 0,
                });
            }
            this.contentType = type;
            this.showContentSelector = true;
        },
        onChangeContentSelector({ convo, message }) {
            if (this.activeRuleId === null) {
                return;
            }
            let value = null;
            if (convo) {
                value = convo.id;
            }
            if (message) {
                value = message.id;
            }
            if (value) {
                this.updateAudienceRule({ id: this.activeRuleId, key: 'value', value });
                this.hydrateRule(this.ruleModel(this.activeRuleId));
            }
        },
        onCloseContentSelector() {
            this.showContentSelector = false;
            this.contentType = ContentType.Any;
            this.clearActiveRuleId();
        },
        handleOperatorToggle(toggle) {
            this.updateAudienceRule({
                key: 'operator',
                id: this.ruleTree.id,
                value: toggle ? 'and' : 'or',
            });
        },
        onChange() {
            this.useAthena = !this.useAthena;
        },
        async updateAudienceSummary() {
            this.updateSummary(this.useAthena);
        },
        handleExportOptionSelection(value) {
            const selectedOption = this.exportOptions.find((o) => o.id === value);
            this.updateAudience({ key: 'exportTo', value });
            this.updateAudience({ key: 'exportPlatform', value: selectedOption.platform });
        },
    },
};
</script>
<style lang="scss" scoped>
.operator-toggle {
    display: flex;
    justify-content: center;
}
/deep/ {
    .select .options {
        z-index: $stepper-z-index + 1;
    }
}
</style>
