<template>
    <ContentPlaceholders>
        <ContentPlaceholdersText :lines="1" class="title" />
        <ContentPlaceholdersText :lines="3" class="items" />
        <ContentPlaceholdersText :lines="1" class="members" />
    </ContentPlaceholders>
</template>

<script>
import { ContentPlaceholders, ContentPlaceholdersText } from '@/components/ContentPlaceholders';
export default {
    name: 'AudienceSummaryPlaceholder',
    components: {
        ContentPlaceholdersText,
        ContentPlaceholders,
    },
};
</script>

<style lang="scss" scoped>
/deep/ {
    .title .content-placeholders-text__line {
        width: 50%;
    }

    .members .content-placeholders-text__line {
        width: 30%;
        margin-left: auto;
    }

    .items .content-placeholders-text__line {
        height: 40px;
    }
}
</style>
